<template>
  <v-sheet :class="'my-cg-order mt-3' + (drawer || editDrawer ||dialog ? ' no-scroll' : '')">
    <v-container>
      <v-row class="mx-0 justify-space-between align-end">
        <div class="d-flex align-center">
          <span
            v-if="product.egift_count < 2"
            class="page-title">
            Order {{ product.order_number }} Placed on {{ product.date }}
          </span>
          <span
            v-else
            class="page-title">
            {{ product.egift_count }} Orders
          </span>

          <div v-if="approvalOrderStatus === 'Declined'">
            <update-status-sheet
              class="ml-4"
              color="#FAE3E3"
              text-color="#9D1216"
              v-tooltip="{
                content: approvalOrderTooltip,
                ...standardTooltipProps,
              }"
            >
              {{ approvalOrderStatus }}
            </update-status-sheet>
          </div>

          <template v-else>
            <template v-if="['Closed', 'Expired', 'Active'].includes(getProductState)">
              <update-status-sheet
                :color="
                  ['Active'].includes(getProductState)
                    ? '#D8F1E4'
                    : ['Closed'].includes(getProductState)
                      ? '#A7DAD8'
                      :'#FA5E5E'
                "
                :text-color="
                  ['Active'].includes(getProductState)
                    ? '#07703A;'
                    : ['Closed'].includes(getProductState)
                      ? '#004642'
                      :'#FA5E5E'
                "
                class="ml-4"
                :outlined="['Expired'].includes(getProductState)"
              >
                {{ getProductState.toUpperCase() }}
              </update-status-sheet>
            </template>

            <update-status-sheet
              v-if="approvalOrderStatus"
              class="ml-4"
              color="#D8F1E4"
              text-color="#07703A"
              v-tooltip="{
                content: approvalOrderTooltip,
                ...standardTooltipProps,
                trigger: approvalOrderStatus === 'Auto-approved' ? 'manual' : 'hover',
              }"
            >
              {{ approvalOrderStatus }}
            </update-status-sheet>
          </template>
        </div>
        <router-link :to="backButtonUrl" class="my-cg-order_back">
          {{ backButtonText }}
        </router-link>
      </v-row>
      <product-card :product="product"/>
      <v-alert
        v-if="isOrderClosed"
        class="black1 py-2 px-10 mb-0 order-alert"
        type="success"
        color="#D8F1E4"
        max-height="65"
        dark
      >
        <span class="grow">The order was closed successfully</span>
      </v-alert>

      <additional-info v-if="approvalOrderStatus" :info="approvalOrderInfo" class="px-0" />

      <v-row class="mx-0 mt-6 mb-2 d-flex flex-start align-center px-0">
        <common-search-form
          :searchIdx="search"
          @setSearchValue="setRecipientSearchValue"
          :iconMaxWidth="13"
          :iconHeight="13"
          :placeholder="'Recipient'"
          :maxWidth="227"
          :height="30"/>
        <div class="ml-auto d-flex align-center">
          <div
            v-if="links.first !== links.last"
            class="d-flex align-center page_num">
            <span class="f14 gray1">Page: </span>
            <common-pagination-base-pagination
              :links="links"
              :meta="meta"
              @change="changePage"
            />
          </div>
          <popper
            :options="{
              placement: 'bottom',
              modifiers: {
                offset: { offset: $vuetify.breakpoint.xl ? '-50px, 5px' : '0, 5px' }
              },
            }"
          >
            <div class="popper pa-4 text-left" style="min-width: 300px">
              {{ closeEgiftTooltip }}
            </div>
            <!-- TODO refactor use vuetify tooltip -->
            <div slot="reference">
              <span
                v-if="showCloseEGiftButton"
                class="f15 py-0 px-4 pointer green2"
                @click="isCloseModalOpened = true"
              >
                CLOSE EGIFT
              </span>
            </div>
          </popper>

          <div
            class="single-link-campaign mr-6"
            tabindex="0"
            @click="copySingleLinkCampaign()"
            @keypress="copySingleLinkCampaign()"
            v-if="orderSingleLinkCampaign"
          >
            <icons-send />

            Copy campaign's link
          </div>

          <common-button-download-csv
            @download="downloadCSV"
            :loading="downloadingCSV"
            class="ml-2 mt-n1 d-flex align-center"
            style="height: 24px"
          />
        </div>
      </v-row>
      <v-row class='mx-0 recipients-table'>
        <common-loader v-if="loading" />
        <v-data-table
          v-if="recipients !== null && !loading"
          :headers="headers"
          :items="recipients"
          :items-per-page="50"
          @click:row="handleRowClick"
          item-key="id"
          single-select
          v-model="selected"
          class="mt-1 e-gift-order-detail"
        >
          <template v-slot:item.recipient_details="{ item }" style="height: 100%">
            <v-row no-gutters :id="item.order_id">
              <v-col cols="12" class="black1">
                <b>{{ item.first_name }}</b><b class="ml-2">{{ item.last_name }}</b>
              </v-col>
              <v-col cols="12" class="gray2">
                {{ item.email_address }}
              </v-col>
              <v-col cols="12" class="gray2">
                {{ item.phone_number }}
              </v-col>
              <v-col v-if="item.company_name" cols="12" class="gray2">
                Company: {{ item.company_name }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.last_sent_email='{ item }'>
            <v-row no-gutters v-if="item.attempts_count > 0">
              <v-col cols="12">
                <dd>{{ item.last_sent | date }}</dd>
              </v-col>
              <v-col cols="12" class="gray2">
                {{ item.attempts_count }} {{ item.attempts_count > 1 ? 'Emails' : 'Email' }}
              </v-col>
            </v-row>
            <v-row no-gutters v-else>
              <v-col cols="12">
                <span class="gray2">
                  {{
                    Number(item.is_generate_links) === 1
                      ? 'Created with dedicated link'
                      : '0 emails sent'
                  }}
                </span>
              </v-col>
              <v-col cols="12">
                <span class="f13" v-if="item.delivery_date"> First due send date: {{ item.delivery_date | date }} </span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.status='{ item }'>
            <div class="d-flex mx-0 flex-column">
              <div class="d-flex f14 black1" v-if="item.status !== 'Bounce'">
                <span class="font-weight-bold text-no-wrap mr-1">E-Gift</span>
                <span>{{ item.status }}</span>
                <popper
                  v-if="!item.status.includes('Redeemed')"
                  :options="{
                    placement: recipients.length > 1 ? 'top' : 'bottom',
                    modifiers: { offset: { offset: '0,10px' } }
                  }">
                  <div
                    class="popper px-4 py-2"
                    :class="'popper'+
                      (recipients.length > 2 ? 3 : recipients.length)+
                      ((item.status === 'Redeemed' || item.status === 'Complete' || item.status.includes('Redeemed')) ? ' big' : ' small')
                    "
                    :placement="recipients.length > 1 ? 'top' : 'bottom'"
                  >
                    <div v-if="!item.status.includes('Declined')" v-html="getOrderTooltipByStatus(item)" />

                    <template v-else>
                      <b>{{ item.refusal.reason }}</b>
                      <br>
                      {{ item.refusal.feedback }}
                    </template>
                  </div>
                  <v-img
                    slot="reference"
                    class="ml-1"
                    src="/images/question-icon.svg"
                    max-width="20"
                    height="20"
                  />
                </popper>
              </div>
              <div class="d-flex f14 red1" v-if="item.status === 'Bounce'">
                <span class="mr-1">E-Gift</span>
                <span>{{ item.status }}</span>
                <popper
                  v-if="item.redeemed !== '1/1' && item.redeemed !== '0/1'"
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                  }">
                  <div class="popper px-4 py-2" :class="'popper'+(recipients.length > 2 ? 3 : recipients.length)">
                    {{ tooltips.filter(tip => tip.status === item.status)[0] !== undefined ? tooltips.filter(tip => tip.status === item.status)[0].tooltip : '' }}
                  </div>
                  <v-img
                    slot="reference"
                    class=" ml-2"
                    src="/images/question-icon.svg"
                    max-width="20"
                    height="20"/>
                </popper>
              </div>
              <div class="d-flex f14 black1 mt-1">
                <span class="font-weight-bold mr-1">Shipping</span>
                <span v-if="item.shipping_status !== null && item.shipping_status.length > 0">{{ item.shipping_status }}</span>
                <span v-if="item.shipping_status === null">
                  {{item.track_url !== null && item.track_url !== undefined ? 'Shipped' : 'N/A'}}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:item.product='{ item }'>
            <div class="d-flex flex-column">
              <!-- TODO refactor use flex instead of margins -->
              <span
                class="black1 font-weight-bold d-flex"
                style="padding-bottom: 2px"
                v-if="
                  itemStatusIsRedeemedOrComplete(item.status) &&
                  (returnArrayLength(item.products) > 1 || product.is_coins_gift)
                "
              >
                {{ returnArrayLength(item.products) }} {{ returnArrayLength(item.products) > 1 ? 'items' : 'item' }}
                <popper
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0, 6px' } }
                  }"
                >
                  <div class="popper pa-4" style="min-width: 300px">
                    <v-row no-gutters class="text-left">
                      <template v-for="(selected, index) in item.products">
                        <v-col cols="12" :key="index">
                          <v-row dense>
                            <v-col cols="3" >
                              <v-img
                                :alt="selected.name"
                                :src="`https://cf.corporategift.com/media/catalog/product${selected.image}`"
                                height="59"
                              />
                            </v-col>
                            <v-col cols="9" class="px-4">
                              <div class="black1 font-weight-bold">
                                {{ selected.name }}
                              </div>
                              <div class="gray2">
                                <span>
                                  ${{ selected.price }}
                                </span>
                                <span v-if="product.is_coins_gift">
                                  | {{ selected.coin_price }} GiftCoin
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                    </v-row>
                  </div>
                  <v-img
                    slot="reference"
                    max-width="20"
                    class="ml-2"
                    height="20"
                    src="/images/question-icon.svg"
                  />
                </popper>
              </span>

              <common-text-with-tooltip
                class="black1 font-weight-bold mb-2"
                make-activator-green
                v-if="
                  itemStatusIsRedeemedOrComplete(item.status) &&
                  returnArrayLength(item.products) <= 1 &&
                  !product.is_coins_gift
                "
              >
                {{ item.order_name === null ? 'Product Name' : item.order_name }}

                <template #tooltip>
                  {{ item.order_name === null ? 'Product Name' : item.order_name }}
                </template>
              </common-text-with-tooltip>

              <div class="product-info-grid">
                <template v-if="itemStatusIsRedeemedOrComplete(item.status)">
                  <div class="product-info-grid__label">Order</div>
                  <div class="product-info-grid__value">{{ item.order_number }}</div>
                </template>

                <template v-if="itemStatusIsRedeemedOrComplete(item.status) && product.is_coins_gift">
                  <div class="product-info-grid__label">GiftCoins used</div>
                  <div class="product-info-grid__value">{{ item.sum_coin_price.toFixed(2) }}</div>
                </template>
                
                <template v-if="itemStatusIsRedeemedOrComplete(item.status)">
                  <div class="product-info-grid__label">Price</div>
                  <div class="product-info-grid__value">{{ item.subtotal | priceFilter }}</div>
                </template>

                <template v-if="itemStatusIsRedeemedOrComplete(item.status) && Number(item.dropship_fee_amount)">
                  <div class="product-info-grid__label">Dropship fee</div>
                  <div class="product-info-grid__value">{{ Number(item.dropship_fee_amount) | priceFilter }}</div>
                </template>

                <template v-if="itemStatusIsRedeemedOrComplete(item.status)">
                  <div class="product-info-grid__label">Shipping</div>
                  <div class="product-info-grid__value">{{ item.shipping | priceFilter }}</div>
                </template>

                <template v-if="itemStatusIsRedeemedOrComplete(item.status)">
                  <div class="product-info-grid__label">Total</div>
                  <div class="product-info-grid__value">{{ calculateItemTotal(item) | priceFilter }}</div>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:item.actions='{ item }'>
            <div
              class='d-flex align-center justify-end'
              v-if="!['Expired', 'Canceled', 'Declined'].includes(item.status) && orderIsPaid"
            >
              <div class="d-flex align-center td-actions">
                <popper
                  v-if="item.message !== null && item.message !== ''"
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '-60px,10px' } }
                  }"
                >
                  <div class="popper text-left px-4 py-4" style="white-space: normal;">
                    {{ item.message }}
                  </div>
                  <div
                    slot="reference"
                    @mouseout='resetBtnState'
                    class='my-cg-order_thanks-note'
                  >
                    <v-img
                      @mouseover='getMousePos'
                      class='my-cg-order_thanks-note_img'
                      src='/images/heart-icon.svg'
                    >
                    </v-img>
                    <span>Thank You Note</span>
                  </div>
                </popper>
                <popper
                  v-if="item.shipping_data && item.shipping_data.length"
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                  }"
                >
                  <div :class="( recipients.length === 1 ) ? 'popper row_one' : 'popper'">
                    <div
                      class='d-flex py-2 mx-4'
                    >
                      <span
                        class="text-left mt-1"
                        style="white-space: normal"
                      >
                        See realtime status for this gift.
                      </span>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    @mouseout="resetBtnState"
                    class="my-cg-order_send-link ml-8"
                  >
                    <img
                      src="/images/location-icon.svg"
                      alt="Location icon"
                      @click="showTracking(item.shipping_data)"
                    />
                    <span>Track Order</span>
                  </div>
                </popper>
                <div
                  v-if="!['Redeemed', 'Complete', 'Canceled'].includes(item.status) && !item.status.includes('Redeemed')"
                  class='my-cg-order_edit-recipient ml-8'>
                  <v-img
                    @click="editRow(item)"
                    contain
                    max-width="15"
                    height="18"
                    src='/images/pen-icon.svg'
                  >
                  </v-img>
                  <span>Edit Recipient Info</span>
                </div>
                <popper
                  v-if="
                    !['Redeemed', 'Complete', 'Canceled' ,'Not paid'].includes(item.status) &&
                    !['single_link_restricted', 'single_link_unrestricted'].includes(product.link_type) &&
                    !item.status.includes('Redeemed') &&
                    item.send_link
                  "
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                  }"
                >
                  <div :class="( recipients.length === 1 ) ? 'popper row_one' : 'popper'">
                    <div
                      v-if='copied'
                      class='d-flex py-2 mx-4'
                    >
                      <v-icon class='confirm-icon mx-2'>mdi-check-circle</v-icon>
                      <span class="text-left mt-1">Link copied to clipboard</span><br>
                      &nbsp;
                    </div>
                    <div
                      v-if='!copied'
                      class='d-flex py-2 mx-4'
                    >
                      <span class="text-left mt-1"
                            style="white-space: normal">Copy link and send it to your recipient.</span>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    @mouseout='resetBtnState'
                    class='my-cg-order_send-link ml-8'
                  >
                    <input
                      type="text"
                      :id="item.id"
                      :value="sendLink"
                      style="width: 0.1px; height: 0.1px; opacity: 0;">
                    <v-img
                      v-on:click='copyLink(item)'
                      class='my-cg-order_send-link_img'
                      src='/images/link-icon.svg'
                      width="16px"
                      height="16px"
                      style="margin-top: -20px; margin-left: -2px;"
                    >
                    </v-img>
                    <span>Send link via email</span>
                  </div>
                </popper>
                <div
                  v-if="!['Redeemed', 'Complete', 'Canceled', 'Not paid'].includes(item.status) && !item.status.includes('Redeemed')"
                  @click='showResendEGiftDialog(item)'
                  class='my-cg-order_resend-egift ml-8 py-1'>
                  <v-img
                    class='my-cg-order_resend-egift_img'
                    src='/images/icon-more.svg'
                  >
                  </v-img>
                  <span>Manage E-Gift</span>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <v-sheet class="overflow-hidden" style="position: relative;">
      <!-- Track History Panel -->
      <v-navigation-drawer
        v-model="drawer"
        v-scroll-lock="drawer"
        app
        temporary
        right
        width="753px"
        class="track-order-panel"
        v-if="this.selected !== undefined"
      >
        <v-list-item
          @click.stop="drawer = !drawer"
          class="pl-10 track-order-panel_close-btn"
        >
          <v-list-item-avatar>
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Close</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-row class="mx-0 pl-13 pt-4 track-order-panel_title">
          Track order to {{ this.selected.first_name + ' ' + this.selected.last_name }}
        </v-row>
        <div class="mt-6 ml-13 track-order-panel_main" v-if="this.selected.track_url !== null">
          <iframe
            :src="this.selected.track_url"
            width="645" height="878"
            style="border: 2px solid #eee"/>
        </div>
      </v-navigation-drawer>
      <!-- Edit Recipient Details Panel -->
      <v-navigation-drawer
        v-model="editDrawer"
        v-scroll-lock="editDrawer"
        app
        temporary
        right
        width="753px"
        class="track-order-panel"
        v-if="tempRecipient !== null"
      >
        <v-list-item
          @click.stop="editDrawer = !editDrawer"
          class="pl-10 track-order-panel_close-btn"
        >
          <v-list-item-avatar>
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Close</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-row class='mx-0 pl-13 pt-4 lato-light f30 gray3'
        >Edit Recipient Details
        </v-row
        >

          <v-row class="d-flex flex-column pl-13 ml-0 mr-10 pr-10">
            <span class="f15 black1 lato-bold pt-9">Full name</span>
            <div class="d-flex mt-2">
              <v-text-field
                :value='tempRecipient.first_name'
                placeholder='First Name'
                class='rounded-0 my-cg-order_search-form'
                color='black'
                outlined
                solo
                flat
                dense
                hide-details
                tile
                @input="setFirstName"
                style="max-width: 206px;"
              />
              <v-text-field
                placeholder='Last Name'
                class='rounded-0 my-cg-order_search-form'
                color='black'
                outlined
                solo
                flat
                dense
                hide-details
                tile
                :value='tempRecipient.last_name'
                @input="setLastName"
                style="max-width: 206px;"
              />
            </div>
          </v-row>
          <v-row class="d-flex flex-column pl-13 ml-0 mr-10 pr-10">
            <span class="f15 black1 lato-bold pt-9">User Email Address</span>
            <div class="d-flex mt-2">
              <v-text-field
                placeholder='Email'
                class='rounded-0 my-cg-order_search-form'
                color='black'
                outlined
                solo
                flat
                dense
                hide-details
                tile
                :value='tempRecipient.email_address'
                @input="setMailAddress"
                style="max-width: 412px;"
              />
            </div>
          </v-row>
          <v-row class="d-flex flex-column pl-13 ml-0 mr-10 pr-10 pt-4">
            <cg-phone-input
              v-model="tempRecipient.phone_number"
              :width="412"
              :height="36"
            >
              <template #label>Telephone</template>
            </cg-phone-input>
          </v-row>
          <v-row class="d-flex flex-column pl-13 pt-4 ml-0 mr-10 pr-10">
            <span class="lato-italic f15 gray1" style="max-width: 410px;">
              Mobile number can be used to send gift-related text notifications to the recipient. This number may also be used to resolve shipping or delivery issues once the recipient redeems his or her gift(s).
            </span>
          </v-row>
          <v-row class='d-flex pl-13 mt-10 ml-0 mr-10 pr-10'>
            <common-button
              width="190"
              @click="updateRecipient()"
            >
              Save
            </common-button>
            <common-button
              class="ml-3"
              width="190"
              outlined
              @click="editDrawer = false"
            >
              Cancel
            </common-button>
          </v-row>
        </v-navigation-drawer>
        <!-- Resend Recipient Panel -->
        <v-navigation-drawer
          v-if="resendingGiftFlag !== false && tempRecipient !== null"
          v-model="dialog"
          v-scroll-lock="dialog"
          class="rounded-0"
          app
          temporary
          right
          width="900px"
        >
          <close-panel-button
            @close="dialog = false"
            text="Close"
          />

        <common-loader v-if="optionsLoading" style="padding: 200px 0" />

        <div class="resendEmailModal" v-else>
          <v-row class="flex-column mx-0 relative">
            <span class="resendEmailModal_header pt-7 pr-12 pb-3">
              {{
                tempRecipient.attempts_count > 0
                  ? `This recipient has received ${tempRecipient.attempts_count} gift notification ${tempRecipient.attempts_count > 1 ? 'emails' : 'email'}`
                  : 'This recipient has not received their gift notification email'
              }}
            </span>
            <span class="f15 gray5 font-italic pr-6 pb-6">
              What would you like to do?
            </span>
          </v-row>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            class="resendEmailModal_tabs"
          >
            <v-tab
              v-for="item in filteredResendTabs"
              :key="item.id"
              @click="showIdx(item)"
              class="d-flex align-center px-0 pt-4 pb-3 resendEmailModal_tab"
            >
              <img class="v-image mb-1 mr-2" :src="item.icon"/>
              <span class="f13">{{ item.name }}</span>
            </v-tab>
          </v-tabs>
          <div
            class="tab-content"
            :style="currentTabId === '0' ? 'visibility: visible' : 'visibility: hidden; height: 0; padding: 0;'">
            <div class="tab-content_description pt-3 pb-1 font-weight-bold">
              <span>Recipient email address/s</span>
            </div>
            <v-text-field
              v-model="tempRecipient.email_address"
              :placeholder="tempRecipient.email_address"
              class="rounded-0 bg-secondary resend-email-input-form"
              color="black"
              outlined
              solo
              flat
              dense
              hide-details
              tile
              height="30"
            />
            <div class="tab-content_description mt-7 pt-3 pb-1 font-weight-bold">
              <span>Email subject line</span>
            </div>
            <v-text-field
              v-model="emailSubject"
              :placeholder="tempRecipient.customer_name + ' sent you a gift'"
              class="rounded-0 bg-secondary resend-email-input-form"
              color="black"
              outlined
              solo
              flat
              dense
              hide-details
              tile
              height="30"
            />
            <div class="tab-content_description mt-7 pt-3 pb-1 font-weight-bold">
              <span>Greeting Message</span>
            </div>
            <v-textarea
              id="email"
              name="email"
              v-model="greetingMsg"
              solo
              :placeholder="'Dear Friend, Thank you so much for everything.  I hope you enjoy this gift!\n\n' + tempRecipient.customer_name"
              class="gift-sending-msg"
              height="200"
              style="width: 408px">
            </v-textarea>
              <div class="pt-6 d-flex">
                <common-button
                  id="preview-hidden"
                  target="_blank"
                  outlined
                  type="button"
                  name="preview"
                  @click="product.is_campaign ? previewCampaignEmail() : previewEmail()"
                >
                  PREVIEW NOTIFICATION
                </common-button>

                <common-button
                  class="ml-3"
                  @click="resendNotification()"
                  :loading="sendingNotification"
                >
                  SEND NOW
                </common-button>
              </div>
            <div v-if="resendNotificationStatus !== undefined">
              <span
                :class="resendNotificationStatus.success ? 'green1' : 'red0'">{{ resendNotificationStatus.message }}</span>
            </div>
          </div>
          <div
            class="tab-content"
            :style="currentTabId === '1' ? 'visibility: visible' : 'visibility: hidden; height: 0; padding: 0;'"
          >
            <div class="tab-content_header">Re-Send Reminder via text:</div>
            <div class="tab-content_description py-3">
              <span
              >Review, update or add the recipient’s mobile phone
                number:</span
              >
            </div>
            <cg-phone-input
              v-model="tempRecipient.phone_number"
              id="phone-number"
              :width="400"
            />
            <div class="pt-6 d-flex">
              <common-button @click="sendSmsReminder()" :disabled="!tempRecipient.phone_number">
                SEND NOW
              </common-button>
              <common-button
                class="ml-3"
                outlined
                id="preview-textmessage-hidden"
                target="_blank"
                :href="'https://corporategift.com/ecp_egift/preview/textmessage/recipientId/' + tempRecipient.id"
              >
                PREVIEW NOTIFICATION
              </common-button>
            </div>
          </div>
          <div
            class="tab-content"
            :style="currentTabId === '2' ? 'visibility: visible; overflow: auto;' : 'visibility: hidden; height: 0; padding: 0;'">
            <form
              id="management-select-gift-form">
              <div class='tab-content_header'>
                Select and Ship the gift to this recipient
              </div>
              <div class='tab-content_step pt-3 pb-10' v-if="giftOptions !== undefined">
                <span class='tab-content_step_header'>1. Select Gift:</span>
                <div class="gift-preview-list d-flex flex-wrap">
                  <div
                    v-for="gift in giftOptions"
                    v-bind:key="gift.id"
                    class="col-md-3"
                  >
                    <div class="favor-gift-card">
                      <div
                        :class="gift.id === selectedGiftID ? 'favor-gift-card_checked-mark' : 'favor-gift-card_checked-mark-passive'">
                        <v-icon>mdi-check-bold</v-icon>
                      </div>
                      <div :class="gift.id === selectedGiftID ? 'favor-gift-card_img' : 'favor-gift-card_img-passive'">
                        <v-img
                          :src="gift.image_url"
                          v-on:click="selectFavorGift(gift.id)"
                        />
                      </div>
                      <div :class="(gift.id === selectedGiftID) ? 'favor-gift-card_name' : 'favor-gift-card_name-passive'">
                        <span>{{ gift.name }}</span>
                      </div>
                      <div v-if="gift.id === selectedGiftID" class="favor-gift-card_selected-txt">
                        <span>Gift Selected</span>
                      </div>
                    </div>
                    <!-- FIELDS -->
                    <div
                      v-if="gift.options !== undefined &&
                        gift.options !== [] &&
                        gift.options.filter(option => option.type === 'field').length > 0
                      "
                    >
                      <div
                        v-for="field in gift.options.filter(option => option.type === 'field')"
                        :key="field.id"
                        class="item-selector"
                      >
                        <label>{{ field.title }}</label>
                        <input
                          class="field-recipient-input select-attribute"
                          :name="`option.${gift.id}.${field.id}`"
                          :id="'option-'+field.id"
                          maxlength="20"
                        >
                        <div class="countdown-container">
                          <span id="countdown-option-28886">0/20</span>
                        </div>
                      </div>
                    </div>
                    <!-- SELECTS -->
                    <div
                      v-if="gift.options !== undefined &&
                        gift.options !== [] &&
                        gift.options.filter(option => option.type === 'drop_down').length > 0
                      "
                    >
                      <div
                        class="item-selector"
                        v-for="option in gift.options.filter(option => option.type === 'drop_down')"
                        :key="option.id"
                      >
                        <div class="select-wrapper d-flex">
                          <select
                            placeholder="Select attribute"
                            v-if="option.values !== []"
                            class="select-attribute"
                            :name="`option.${gift.id}.${option.id}`"
                            :id="'option-'+option.id">
                            <option value="" disabled selected>Select attribute</option>
                            <option
                              v-for="value in option.values"
                              :key="value.id"
                              :value="value.id"
                            >
                              {{ value.title }}
                            </option>
                          </select>
                          <v-icon style="color: black; margin-left: -24px">mdi-chevron-down</v-icon>
                        </div>
                      </div>
                    </div>
                    <div class="item-getter">
                      <input
                        style="display: none;"
                        type="radio"
                        name="selectedProduct[]"
                        :id="'product-selected-'+gift.id"
                        :value="gift.id">
                    </div>
                  </div>
                </div>
              </div>
              <v-divider/>
              <div class='tab-content_step pt-3'>
                <span class='tab-content_step_header'>2. Gift Message:</span>
                <div class="gift-message-inputs mt-6">
                  <input type="hidden" name="checkoutCode" id="recipientCheckoutCode" :value="tempRecipient.quote_id">
                  <input type="hidden" name="recipientId" id="recipientId" :value="tempRecipient.id">
                  <input class="smartgift-input input required email" type="hidden" id="emailRecipient"
                        name="emailRecipient" required="" :value="tempRecipient.email_address">
                  <div class="from-input input-row pb-2">
                    <label class="gift-message-label">From</label>
                    <v-text-field
                      id="senderName"
                      name="senderName"
                      v-model="resendGiftData.senderName"
                      placeholder="Sender Name"
                      class="rounded-0 bg-secondary sender-name"
                      color="black"
                      outlined
                      solo
                      flat
                      dense
                      hide-details
                      tile
                      height="30"
                      required
                    />
                  </div>
                  <div class="message-input input-row d-flex">
                    <label class="gift-message-label">Message</label>
                    <v-textarea
                      id="thankyou_message"
                      name="thankyou_message"
                      v-model="resendGiftData.thankyou_message"
                      solo
                      :placeholder="'Dear Friend, Thank you so much for everything. I hope you enjoy this gift!\nCheers,\n' + resendGiftData.senderName"
                      class="gift-sending-msg"
                      height="140">
                    </v-textarea>
                  </div>
                </div>
              </div>
              <v-divider/>
              <div class="preview-shipping-wrapper">
                <div class='tab-content_step py-3'>
                  <span class='tab-content_step_header'>3. Deliver this Gift To:</span>
                </div>
                <div class="preview-shipping-block">
                  <div class="shipping-inputs-wrapper">
                    <div class="shipping-input-row double-field">
                      <label for="preview-first-name">Name</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          v-model="resendGiftData.first_name"
                          placeholder="First Name"
                          class="required rounded-0 bg-secondary recipient-name"
                          id="first_name"
                          name="firstname"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                          height="30"
                        />
                        <v-text-field
                          placeholder="Last Name"
                          class="required rounded-0 bg-secondary recipient-name"
                          id="last_name"
                          name="lastname"
                          v-model="resendGiftData.last_name"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                          height="30"
                        />
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label for="telephone">Email</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          placeholder="Email"
                          class="required rounded-0 bg-secondary street-address"
                          id="email"
                          v-model="resendGiftData.email"
                          name="email"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                        />
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label for="telephone">Telephone</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          placeholder="(000) 000-0000"
                          class="required rounded-0 bg-secondary street-address"
                          id="telephone"
                          v-model="resendGiftData.telephone"
                          name="phone"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                        />
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label for="preview-first-name">Street Address</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          placeholder="P.O.Box 23212"
                          class="required rounded-0 bg-secondary street-address"
                          id="address_line1"
                          v-model="resendGiftData.address_line1"
                          name="street"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                        />
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label for="preview-first-name">City</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          placeholder="Dallas"
                          class="required rounded-0 bg-secondary city-name"
                          id="city"
                          v-model="resendGiftData.city"
                          name="city"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                        />
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label>State/Region</label>
                      <div class="recipient-name-group">
                        <v-select
                          id="state"
                          v-model="resendGiftData.state"
                          name="state"
                          placeholder="Please select region, state or province"
                          :items="regions.map(item => item.txt)"
                          label=""
                          dense
                          outlined
                          solo
                          flat
                          hide-details
                          tile
                          class="region-attr-select-form">
                        </v-select>
                      </div>
                    </div>
                    <div class="shipping-input-row double-field">
                      <label for="preview-first-name">Postal Code</label>
                      <div class="recipient-name-group">
                        <v-text-field
                          placeholder=""
                          class="required rounded-0 bg-secondary street-address"
                          id="zip"
                          v-model="resendGiftData.zip"
                          name="postcode"
                          color="black"
                          outlined
                          solo
                          flat
                          dense
                          hide-details
                          tile
                        />
                      </div>
                    </div>
                  </div>
                  <div class='pt-10 d-flex flex-column'>
                    <div class="d-flex" style="position: relative;">
                      <common-button
                        class="resend-gemail_send-now"
                        width="300"
                        :disabled="resendingGiftFlag"
                        @click="shipToRecipientAtThisAddress()"
                      >
                        SEND TO RECIPIENT AT THIS ADDRESS
                      </common-button>
                      <div v-if="resendingGiftFlag" style="position: absolute; left: 128px;">
                        <v-progress-circular
                          :indeterminate="resendingGiftFlag"
                          color="green"
                        />
                      </div>
                    </div>
                    <div class="red1">
                      {{ errorMessage }}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            class='tab-content'
            :style="currentTabId === '3' ? 'visibility: visible' : 'visibility: hidden; height: 0; padding: 0;'">
            <div class='tab-content_header'>
              Are you sure you want to cancel eGift for {{ tempRecipientFullName }}?
            </div>
            <div class='tab-content_description py-3'>
              <span>
                By cancelling this gift, this recipient will no longer receive emails or be able to
                redeem their gift. The value of this gift (including any prepaid shipping and sales
                taxes) will be refunded when the <b>full eGift order </b> (including all recipients) is closed.
              </span>
            </div>
            <div class="pt-10">
              <common-button
                class="resend-gemail_send-now"
                @click="cancelGift()"
              >
                YES, CANCEL THIS GIFT
              </common-button>
            </div>
          </div>
        </div>
      </v-navigation-drawer>
      <close-order
        :visible.sync="isCloseModalOpened"
        :refund="refund"
        :id="product.id"
        :isCollection="product.is_collection"
        :productState="getProductState"
        :productDate="product.date"
        :isPreProduction="isPreProduction"
        :showLoading="closingRecipientGift"
        :hideCloseButton="hideCloseButtonInCloseGiftPanel"
        :closeAfterSuccess="hideClosePanelAfterSuccess"
        @refund-error="closePanelRefundErrorHandler()"
        @closed="closedHandler"
      />

      <multiple-tracking-panel
        v-model="showMultipleTrackingPanel"
        :trackingData="multipleTrackingData"
      />
    </v-sheet>
  </v-sheet>
</template>

<script>
import _ from 'lodash'

import 'vue-popperjs/dist/vue-popper.css'
import { createNamespacedHelpers } from 'vuex'
import { recipientsTableHeaders, trackHeaders } from '@/assets/headers'
import { tooltips, regions, resendEmailTabs } from '@/assets/data/eGiftOrder'
import getOrderApprovalStatus from '@/utils/getOrderApprovalStatus'

import Api from '@/axios/api'
// TODO check if this is used??
import userName from '@/mixins/userName'
import companyName from '@/mixins/companyName'
import standardTooltipProps from '@/mixins/standardTooltipProps'

import Popper from 'vue-popperjs'
import CloseOrder from '@/components/myEgift/CloseOrder'
import MultipleTrackingPanel from '@/components/myEgift/MultipleTrackingPanel'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'
import { CgPhoneInput } from '@corporategift/design-system'

import AdditionalInfo from '@/components/myOrders/AdditionalInfo'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('e-gift-order')

export default {
  name: 'e-gift-order',
  mixins: [
    userName,
    companyName,
    standardTooltipProps,
  ],
  components: {
    CloseOrder,
    AdditionalInfo,
    CgPhoneInput,
    popper: Popper,
    UpdateStatusSheet,
    MultipleTrackingPanel
  },
  data: () => ({
    downloadedCSV: false,
    downloadingCSV: false,
    dialog: false,
    editDialog: false,
    drawer: null,
    editDrawer: false,
    tempRecipient: {
      id: 0,
      subject: '',
      email: '',
      quote_id: '',
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      last_sent: '',
      attempts_count: 0,
      egift_status: '',
      shipping_status: '',
      message: '?',
      send_link: '',
      track_url: null,
      name: '',
      subtotal: '',
      shipping: null,
      order_number: ''
    },
    closingRecipientGift: false,
    hideCloseButtonInCloseGiftPanel: false,
    hideClosePanelAfterSuccess: false,
    selected: undefined,
    position_x: 0,
    position_y: 0,
    headers: recipientsTableHeaders,
    trackHeaders,
    tab: null,
    resendEmailModalTabs: resendEmailTabs,
    regions,
    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    },
    tooltips,
    copiedText: '',
    editRecipientInfoF: false,
    snack: false,
    snackColor: '',
    snackText: '',
    max50chars: v => v.length <= 50 || 'Input too long!',
    pagination: {},
    selectedID: null,
    resendGiftData: {
      senderName: '',
      recipientName: '',
      thankyou_message: '',
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      zip: '',
      telephone: '',
      telephone_ext: ''
    },
    errorMessage: '',
    selectedGiftOption: [],
    currentTabId: '0',
    greetingMsg: '',
    emailSubject: '',
    isCloseModalOpened: false,
    optionsLoading: false,
    refund: {},
    isOrderClosed: false,
    hideCloseButton: false,
    showMultipleTrackingPanel: false,
    multipleTrackingData: []
  }),
  computed: {
    ...mapState([
      'recipients', 'product', 'sendLink', 'links', 'meta', 'copied', 'resendingGiftFlag',
      'resendNotificationStatus', 'giftOptions', 'selectedGiftID', 'search',
      'ordersSize', 'sendingNotification'
    ]),
    ...mapGetters([
      'getProductState',
      'egiftId',
      'egiftFilters',
      'loading',
      'orderSingleLinkCampaign'
    ]),
    closeEgift () {
      const { product: { estimated, status, total } } = this

      return ['Complete', 'Redemption complete'].includes(status) && Number(estimated) > Number(total)
    },
    isPreProduction () {
      return this.product?.is_pre_production ?? false
    },
    filteredResendTabs () {
      const { resendEmailModalTabs } = this

      return resendEmailModalTabs
    },
    closeEgiftTooltip () {
      return this.$store.getters['tooltips/getTooltipTextBySlug']('close-egift')
    },
    backButtonText () {
      return this.$store.getters['e-gift-order/backButton'].text
    },
    backButtonUrl () {
      return this.$store.getters['e-gift-order/backButton'].url
    },
    showCloseEGiftButton () {
      const { hideCloseButton, product: { close, date } } = this
      const orderDate = new Date(date)
      const closeEGiftRelease = new Date('03/15/2021')
      if (closeEGiftRelease.getTime() > orderDate.getTime()) return false

      return close && !hideCloseButton;
    },
    orderIsPaid () {
      return this.product?.is_paid ?? false
    },
    scrollToRecipientOrderId () {
      return this.$route?.params?.recipientId
    },
    tempRecipientFullName () {
      const { tempRecipient } = this

      if (!tempRecipient) { return null }

      return [tempRecipient?.first_name, tempRecipient?.last_name].join(' ')
    },
    approvalOrderStatus () {
      // Approved | Declined | Auto-approved
      const status = this.product?.approval?.status
      return getOrderApprovalStatus(status)
    },
    approvalOrderTooltip () {
      const { approvalOrderStatus } = this
      const approval = this.product?.approval ?? {}

      return `${approvalOrderStatus} by ${approval?.decided_by}`
    },
    approvalOrderInfo () {
      return this.product?.approval?.data ?? []
    },
  },
  watch: {
    dialog (val) {
      this.tempRecipient = val === false ? null : this.tempRecipient
    },
    async product (val) {
      if (val.close && val.id) {
        await Api.get('/customer/egifts/' + val.id + '/refund')
          .then(resp => {
            this.refund = resp
          })
          .catch((e) => (new Error(e)))
      }
    },
  },
  created () {
    if (this.$route.params.id && this.$route.params.id !== 'merged-report') {
      this.$store.commit('e-gift-order/setEgiftId', this.$route.params.id)
    }

    this.loadSummary()
      .then(() => {
        this.$route.meta.breadcrumbs.at(-1).name = `Order ${this.product.order_number}`
        this.$router.replace().catch(() => {});

        if (!this.product.egift_count) {
          this.$cgToast.error("Can't find an order with a specific id")
          this.$router.push({ name: 'Orders' })
        }
      })
      .catch(() => {
        this.$cgToast.error("Can't find an order with a specific id")
        this.$router.push({ name: 'Orders' })
      })

    this.loadRecipients()
      .then(() => (this.scrollToRecipientRowWithId()))
  },
  updated () {
    this.scrollToRecipientRowWithId()
  },
  methods: {
    ...mapActions([
      'loadSummary',
      'loadRecipients',
      'loadCSV'
    ]),
    handleRowClick (e, v, m) {
    },
    showTrackHistory (item) {
      this.drawer = true
      this.selected = item
    },
    copyLink (item) {
      this.selectedID = item.id
      this.$store.dispatch('e-gift-order/getGiftOrderSendLink', item)
    },
    resetBtnState () {
      this.$store.commit('e-gift-order/resetCopiedStatus')
      this.downloadedCSV = false
    },
    downloadCSV () {
      this.downloadingCSV = true
      this.$store.dispatch('e-gift-order/loadCSV').then(() => (this.downloadingCSV = false))
    },
    getMousePos (e) {
      this.position_x = e.position_x
      this.position_y = e.position_y
    },
    formatModalStatus () {
      this.$store.commit('e-gift-order/resetResendingGiftFlag')
      this.resendGiftData = {
        senderName: '',
        recipientName: '',
        thankyou_message: '',
        first_name: '',
        last_name: '',
        company: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        zip: '',
        telephone: '',
        telephone_ext: ''
      }
    },
    showResendEGiftDialog (recipient) {
      this.dialog = true
      this.optionsLoading = true
      this.tempRecipient = recipient
      this.greetingMsg = this.product.message
        ? this.product.message
        : (recipient.message !== null && recipient.message !== '')
          ? recipient.message
          : ('Dear Friend, Thank you so much for everything.  I hope you enjoy this gift!\n\n' + recipient.customer_name)

      const customerName = recipient?.customer_name ?? null

      this.emailSubject = this.product?.subject_line
        ? this.product?.subject_line?.replace('<Gift giver name>', customerName)
        : (customerName)
          ? (customerName + ' sent you a gift')
          : 'Send you a gift'
      this.formatModalStatus()
      this.resendGiftData.senderName = recipient.customer_name
      this.resendGiftData.recipientName = recipient.first_name + ' ' + recipient.last_name
      this.resendGiftData.first_name = recipient.first_name
      this.resendGiftData.last_name = recipient.last_name
      this.$store.commit('e-gift-order/setNotificationStatus', undefined)
      this.$store.dispatch('e-gift-order/getGiftOptions', recipient.id).then(() => (this.optionsLoading = false))
    },
    showIdx (tab) {
      this.currentTabId = tab.id
    },
    selectFavorGift (idx) {
      this.$store.commit('e-gift-order/setSelectedGiftID', idx)
    },
    save () {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    close () {
    },
    closedHandler () {
      this.loadSummary()
      this.loadRecipients()
      this.hideCloseButtonInCloseGiftPanel = false
      this.hideClosePanelAfterSuccess = false
    },
    closePanelRefundErrorHandler () {
      this.isCloseModalOpened = false
      this.hideCloseButton = true
      this.hideCloseButtonInCloseGiftPanel = false
      this.hideClosePanelAfterSuccess = false
    },
    editRow (item) {
      this.editDrawer = !this.editDrawer
      this.tempRecipient = item
    },
    setRecipientSearchValue: _.debounce(function (searchValue) {
      this.$store.dispatch('e-gift-order/search', searchValue)
    }, 700),
    setFirstName (e) {
      this.tempRecipient.first_name = e
    },
    setLastName (e) {
      this.tempRecipient.last_name = e
    },
    setMailAddress (e) {
      this.tempRecipient.email_address = e
    },
    setPhoneNumber (e) {
      this.tempRecipient.phone_number = e
    },
    updateRecipient () {
      this.$store.dispatch('e-gift-order/updateRecipient', this.tempRecipient)
      this.editDrawer = !this.editDrawer
    },
    resendNotification () {
      const recipientClone = _.cloneDeep(this.tempRecipient)
      recipientClone.message = this.greetingMsg
      recipientClone.subject = this.emailSubject
      this.$store.dispatch('e-gift-order/resendNotification', recipientClone)
      this.$store.dispatch('e-gift-order/updateRecipient', recipientClone)
    },
    sendSmsReminder () {
      const { phone_number, id } = this.tempRecipient

      Api.put(`/customer/recipients/${id}/send-sms`, { phone_number })
        .then(({ data }) => {
          this.$cgToast.successBold(data?.message ?? 'Message has been send.')
        })
        .catch(() => {
          this.$cgToast.error('An error occurred, please contact our support')
        })
    },
    cancelGift () {
      this.closingRecipientGift = true
      const giftCanBeClosed = this.product.close && !this.hideCloseButton

      // we need info about the total recipients to make this functionality work
      // const numberOfNonCanceledRecipients = this.recipients.filter((item) => item.status !== 'Canceled').length

      const numberOfNonCanceledRecipients = 2

      this.$store.dispatch('e-gift-order/cancelGift', this.tempRecipient.id)
        .then(() => {
          this.dialog = false
          if (giftCanBeClosed && numberOfNonCanceledRecipients <= 1) {
            this.isCloseModalOpened = true
            this.hideCloseButtonInCloseGiftPanel = true
            this.hideClosePanelAfterSuccess = true
          }
        }).finally(() => {
          this.closingRecipientGift = false
        })

      if (giftCanBeClosed && numberOfNonCanceledRecipients <= 1) {
        setTimeout(() => {
          this.isCloseModalOpened = true
          this.hideCloseButtonInCloseGiftPanel = true
          this.hideClosePanelAfterSuccess = true
        }, 2000)
      }
    },
    changePage (page) {
      this.$store.dispatch('e-gift-order/loadSelectedPage', page)
    },
    previewCampaignEmail () {
      const { product, tempRecipient } = this

      const queryObject = {
        sender: this.companyName,
        email: tempRecipient.email_address,
        first_name: tempRecipient.first_name,
        last_name: tempRecipient.last_name,
        greeting_message: this.greetingMsg,
        email_subject_line: this.emailSubject,
        logo_url: product?.logo_url,
        can_regift: +product?.can_regift,
        can_upgrade: +product?.can_upgrade
      }
      if (product?.video_greeting) queryObject.video_greeting = product?.video_greeting
      if (product?.calendly_link) queryObject.calendly_link = product?.calendly_link

      const queryParams = new URLSearchParams(queryObject)

      const egiftLink = process.env.VUE_APP_EGIFT_URL || 'https://egift.staging.corporategift.com'

      window.open(`${egiftLink}/recipient/preview/c/${btoa(product.product_id)}/gift?${queryParams}`, '_blank')
    },
    previewEmail () {
      const formData = new FormData(document.getElementById('management-select-gift-form'))
      const data = {}
      // need to convert it before using not with XMLHttpRequest
      for (const [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }
      const aa = 'productsChecked=' + this.product.product_id + '&' + 'senderEmail=' + this.product.product_id + '&' +
        'logoUrl=' + this.$store.state.header.headerData.logoUrl + '&' + 'recipientFirstName=' + data.recipientName + '&' +
        'recipientLastName=' + '' + '&' + 'recipientEmail=' + data.emailRecipient + '&' +
        'ecardMessage=' + this.greetingMsg + '&' + 'subjectLine=' + (this.emailSubject || this.product.product_id) + '&' +
        'videoUrl=' + this.product.product_id + '&' + 'recipientId=' + this.tempRecipient.id + '&' +
        'giftId=' + this.product.product_id
      window.open(process.env.VUE_APP_MAGENTO_URL + '/ecp_egift/preview/?preview=' + btoa(aa), '_blank')
    },
    shipToRecipientAtThisAddress () {
      if (this.checkFormValidation()) {
        const formData = new FormData(document.getElementById('management-select-gift-form'))
        const { selectedGiftID: productId } = this
        const data = {}
        const selectedProductOption = {}

        for (const [key, val] of formData.entries()) {
          if (key.includes('option')) {
            if (key.includes(productId)) {
              const [_name, _prodId, optionId] = key.split('.')
              selectedProductOption[optionId] = val
            }
          } else {
            Object.assign(data, { [key]: val })
          }
        }
        // check if product has selected options
        if (Object.keys(selectedProductOption).length) {
          data.option = {}
          data.option[productId] = selectedProductOption
        }

        if (this.checkFormValidation()) {
          this.$store.commit('e-gift-order/setRedeemGiftHash', this.tempRecipient?.hash)
          this.$store.dispatch('e-gift-order/redeemGift', data)
        }
      }
    },
    checkFormValidation () {
      if (this.selectedGiftID === undefined) {
        this.errorMessage = 'Please select a gift.'
        return false
      }
      if (!this.resendGiftData.first_name) {
        this.errorMessage = 'Please write First Name.'
        return false
      }
      if (!this.resendGiftData.last_name) {
        this.errorMessage = 'Please write Last Name.'
        return false
      }
      if (!this.resendGiftData.address_line1) {
        this.errorMessage = 'Street address field can not be empty.'
        return false
      }
      if (!this.resendGiftData.city) {
        this.errorMessage = 'City field can not be empty.'
        return false
      }
      if (!this.resendGiftData.email) {
        this.errorMessage = 'Email field can not be empty.'
        return false
      }
      if (!this.resendGiftData.state) {
        this.errorMessage = 'Please select State/Region.'
        return false
      }
      if (!this.resendGiftData.zip) {
        this.errorMessage = 'Postal Code field can contain only numbers and can not be empty.'
        return false
      }
      if (!this.resendGiftData.telephone) {
        this.errorMessage = 'Phone number has to match (000) 000-0000 pattern.'
        return false
      }

      this.errorMessage = ''
      return true
    },
    getPreselectedProducts (array) {
      return array?.filter(element => element.is_preselected)
    },
    getPreselectedProductsLength (array) {
      return this.getPreselectedProducts(array)?.length || 0
    },
    returnArrayLength (array) {
      return array?.length || 0
    },
    itemStatusIsRedeemedOrComplete (itemStatus) {
      return ['Redeemed', 'Complete'].includes(itemStatus) || itemStatus.includes('Redeemed')
    },
    calculateItemTotal (item) {
      let total = 0;

      total += +item.shipping
      total += +item.subtotal
      total += +item.dropship_fee_amount

      return total
    },
    showTracking (trackingData) {
      if (trackingData.length === 1) {
        window.open(trackingData[0].track_url, '_blank')
      } else {
        this.showMultipleTrackingPanel = true
        this.multipleTrackingData = trackingData
      }
    },
    getOrderTooltipByStatus ({ status }) {
      if (status.includes('Redeemed')) return this.tooltips?.filter((tooltip) => tooltip.status === 'Redeemed')[0]?.tooltip

      return tooltips.filter((tooltip) => tooltip.status === status)[0]?.tooltip || ''
    },
    copySingleLinkCampaign () {
      const { orderSingleLinkCampaign: link } = this

      navigator.clipboard.writeText(link);
      this.$cgToast.success('Link copied to clipboard')
    },
    scrollToRecipientRowWithId () {
      const { recipients, scrollToRecipientOrderId } = this

      if (recipients.length && scrollToRecipientOrderId) {
        this.$nextTick(() => {
          const recipientRow = document.getElementById(scrollToRecipientOrderId)
          const header = document.querySelector('header')
          const headerHeight = header.offsetHeight
          const recipientTopPosition = recipientRow.getBoundingClientRect().top

          window.scrollTo({
            left: 0,
            top: ((recipientTopPosition + window.scrollY - 20) - headerHeight),
            behavior: 'smooth',
          })
        })
      }
    }
  },
}
</script>
// :(
<style lang='scss'>
.my-cg-order {

  &.no-scroll {
    height: calc(100vh - 180px);
    overflow: hidden;
  }

  &_back {
    text-decoration: none !important;
    color: #00a86b !important;
  }

  &_product-card {
    border: 1px solid #e6e6e6;

    &_logo {
      max-width: 163px;
    }

    &_product-info {
      &_title {
        text-transform: uppercase;
        color: #b8b8b8;
        font-size: 14px;
      }

      &_description,
      &_mark {
        max-width: 175px;
      }

      &_description {
        font-family: 'Lato-Bold';
        font-size: 14px;
        line-height: 23px;
        text-align: left;
      }

      &_mark {
        font-size: 12px;
        line-height: 15px;
        text-align: left;
      }

      &_greet-msg {
        font-family: 'Lato-Bold';
        font-size: 14px;
      }
    }

    &_actual {
      font-size: 14px;
      line-height: 33px;

      &_title,
      &_price {
        font-family: 'Lato-Bold';
        text-transform: uppercase;
        font-weight: 600;
      }

      &_title {
        min-width: 185px;
      }
    }

    &_total {
      border-top: 6px solid #e4e4e4;
      min-width: 322px;
      font-family: 'Lato-Bold';
      font-weight: 600;
      line-height: 40px;
    }
  }

  &_search-form {

    .v-input__slot {
      font-size: 14px;
      padding: 0 !important;

      .v-input__prepend-inner {
        padding: 0 !important;
      }

      .v-input__icon {
        .v-icon {
          font-size: 16px;
        }
      }

      input {
        padding: 9px 13px;
      }
    }
  }

  .last-sent-email-form,
  .status-form {
    position: relative;
    min-width: 160px;
    // display: flex;
    justify-content: space-between;
    align-items: center !important;
    padding-right: 0 !important;

    i {
      right: 0;
    }
  }

  &_download-csv,
  &_thanks-note,
  &_edit-recipient,
  &_resend-egift,
  &_send-link {
    cursor: pointer;
    position: relative;

    &_img {
      max-width: 24px;
      object-fit: contain;
      overflow: visible;

      .csv-popup {
        position: absolute;
      }
    }

    span {
      position: absolute;
      margin-top: 10px;
      display: none;
      font-size: 12px;
      padding: 5px 13px 5px 16px;
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }

    &:hover {
      span {
        display: block;
        border: 1px solid #b3cac9 !important;
        border-radius: 2.6px !important;
        background-color: #d2f5f3 !important;
      }
    }
  }

  &_link-copy {
    min-width: 748px;
    object-fit: contain;
    margin-right: -4px;
  }

  .recipients-table {
    overflow: auto;

    @media screen and (min-width: 1264px) {
      overflow: visible;
    }

    .v-data-table {
      width: 100%;
      min-width: 1100px;

      .v-data-table__wrapper {
        @media screen and (min-width: 1264px) {
          overflow: visible;
        }

        @media screen and (max-width: 1280px) {
          padding-right: 40px;
        }
      }

      .v-data-footer {
        display: none;
      }

      table {
        border-bottom: 1px solid #d3d2d2;

        thead {
          th:nth-last-child(2) {
            border-right: none !important;
          }
        }

        tbody {
          tr {
            cursor: pointer;

            td {
              overflow: hidden;
              height: 100%;

              &:last-child {
                overflow: visible;
              }
            }

            td > div {
              padding: 18px 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.text-start {
  align-items: flex-start !important;
}

.e-gift-order-detail {
  .v-data-table__selected {
    background: #fff9f9 !important;

    td:first-child {
      background: #fff9f9 !important;
    }
  }

  .thanks-note {
    max-width: 15px;
    background: red;

    &:hover {
      .thanks-note_tooltip {
        visibility: visible;
      }
    }
  }

  &_tooltip-thanks-note,
  &_tooltip-send-link,
  &_tooltip-send-link-active,
  &_tooltip-download-csv {
    color: black;
    background: white;
    border-radius: 2.6px;
    padding: 0 13px 0 18px !important;
    max-width: 223px !important;
    margin-top: -15px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 101%;
      border-width: 5px;
      width: 15px;
      height: 15px;
      transform: rotate(45deg) translate(-50%, -50%);
      background: white;
    }
  }

  &_tooltip-thanks-note {
    padding-top: 4px !important;

    &::before {
      margin-left: 44px;
    }
  }

  &_tooltip-send-link,
  &_tooltip-download-csv {
    &::before {
      margin-left: 170px;
    }
  }
}

.track-order-panel {
  &_title {
    color: #626262;
    font-size: 30px;
    font-family: 'Lato-Light';
  }

  &_close-btn {
    .v-list-item__title {
      color: #219358;
      font-size: 14px;
    }

    .v-icon {
      color: black !important;
      font-size: 50px !important;
      margin-right: 0 !important;
    }
  }

  &_main {
    width: 640px;
    border: 2px solid #f5f5f5;

    &_header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      background-color: #f9f9f9;
      font-size: 24px;
      font-family: sans-serif;
      font-weight: 600;
    }

    &_logo {
      &_mark {
        font-size: 28px;
        line-height: 28px;
      }

      &_num {
        font-size: 14px;
        color: #f5a451;
      }
    }

    .redirect-link-part {
      span {
        color: #097ccf;
        font-family: 'Lato-Bold';
      }
    }

    .out-for-delivery {
      height: 70px;
      background-color: #f5a451;
      color: white;
      font-size: 14px;
      margin: 0 -1px;
    }
  }

  .track-order-list {
    .track-order-item-left {
      &_icon {
        margin-left: auto;
      }
    }

    .track-order-item-right {
      &_description {
        font-size: 16px;
        font-family: 'Lato-Bold';
        max-width: 355px;
      }

      .appendix-usp {
        background-color: #ecf0f1;
        font-size: 16px;
        color: #b8b8b8;
        max-height: 24px;
      }

      &_location {
        font-size: 16px;
        color: #626262;
      }
    }

    &_date {
      font-size: 16px;
      font-family: 'Lato-Bold';
    }

    &_time {
      font-size: 16px;
      color: #626262;
    }
  }

  .v-data-table-header {
    display: none;
  }

  .v-data-footer {
    display: none;
  }
}

.v-dialog {
  border-radius: 0;
  position: absolute;
  overflow: visible;
}

.close-icon {
  position: absolute !important;
  font-size: 50px !important;
  font-weight: 600;
  right: -60px;
}

.resendEmailModal {
  overflow: hidden;
  margin-left: 61px;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &_header {
    font-family: 'Lato-Light';
    font-size: 30px;
    line-height: 36px;
    color: #16181f;
    background: #ffffff;
  }

  &_subheader {
    font-family: 'Lato-Bold';
    font-size: 20px;
    line-height: 24px;
    color: black;
    background: #ffffff;
  }

  &_tabs {
    background: #ffffff;

    .v-slide-group__prev,
    .v-slide-group__next {
      display: none;
    }
  }

  &_tab {
    color: #9b9b9b;
    font-family: 'Lato-Bold';
    font-size: 13px;
    width: 192px;
    letter-spacing: normal;
    border: 2px solid #dbdbdb;
    background: #F3FFFE;

    .v-image {
      opacity: 0.3;
    }

    span {
      font-weight: bold;
      letter-spacing: 0;
    }

    &:active {
      color: #4a4a4a;
      border-bottom: none;
    }
  }

  &_tab:not(:last-child) {
    border-right: none;
  }

  .v-tab--active {
    border-bottom: none;
    background: #FFF;

    .v-image {
      opacity: 0.7;
    }
  }

  .item-selector {
    margin-bottom: 10px;

    label {
      color: #959595;
      font-weight: 400;
      margin-bottom: 0;
    }

    .select-attribute {
      padding: 0 20px 0 10px;
      font-weight: 400 !important;
      font-style: normal !important;
      color: #000 !important;
      width: 100%;
      border-radius: 0;
      height: 28px;
      background-color: #fff;
      border: 1px solid #d3d2d2;
      font-size: 14px;
      font-family: "Lato", Verdana, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.tab-content {
  padding: 55px 54px 37px 0px;

  &_header {
    font-family: 'Lato-Light';
    font-size: 30px;
    line-height: 36px;
    color: #626262;
  }

  &_description {
    font-size: 15px;
    line-height: 18px;
    color: black;
  }

  &_step {

    &_header {
      font-family: 'Lato-Bold' !important;
      font-size: 20px;
      line-height: 24px;
    }

    label {
      font-family: 'Lato-Regular';
    }
  }

  .resend-email-input-form {
    width: 408px;
  }

  .gift-attr-select-form,
  .region-attr-select-form {
    max-width: 143px;
    border-radius: 0;

    .v-input__control {

      .v-label {
        top: 5px;
        font-style: italic;
        color: #a1a1a1;
        font-weight: 400;
        font-size: 14px;
      }

      .v-select__selection--comma {
        font-size: 14px;
      }
    }
  }

  .region-attr-select-form {
    min-width: 368px;
  }

  .phone-number {
    width: 254px;
  }

  .ext-number {
    width: 113px;
  }
}

.v-tabs-bar {
  height: 44px !important;
}

.v-tabs-slider-wrapper {
  height: 3px !important;

  .v-tabs-slider {
    margin-top: -41px;
    margin-left: 2px;
  }
}

.resend-gemail {
  &_send-now,
  &_review-note {
    font-family: 'Lato-Bold' !important;
    font-size: 15px !important;
    line-height: 18px;

    .v-btn__content {
      letter-spacing: 0;
      padding-top: 2px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &_send-now {
    background: black !important;
  }

  &_review-note {
    border: 2px solid black;
    background: #ffffff !important;
  }
}

.tooltip-triangle {
  content: '';
  position: absolute;
  border-width: 5px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg) translate(-50%, -50%);
  background: red;
}

.favor-gift-card {
  max-width: 142px;
  font-weight: 400;

  &_checked-mark,
  &_checked-mark-passive {
    width: 37px;
    min-height: 37px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    margin-left: auto;

    .v-icon {
      color: white;
      margin-top: -2px;
    }
  }

  &_checked-mark {
    background-color: #42B77A !important;
  }

  &_checked-mark-passive {
    background-color: #fff !important;
  }

  &_img,
  &_img-passive {
    object-fit: contain;
    max-width: 157px;
  }

  &_img-passive {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &_name,
  &_name-passive,
  &_selected-txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    max-width: 142px;
    margin: 6px auto;
  }

  &_name {
    color: #4a4a4a;
  }

  &_name-passive {
    opacity: 0.5!important;
  }

  &_selected-txt {
    color: #77cb9f;
  }
}

.gift-message-inputs {
  font-weight: 400;
  font-size: 15px;
  color: black;
}

.from-input,
.message-input {
  max-width: 527px;
  display: flex;
  justify-content: space-between;

  .sender-name,
  .gift-sending-msg {
    max-width: 367px;
  }

  .gift-sending-msg {

    .v-input__control {
      height: 140px;
    }

    .v-input__slot {
      border: 1px solid #d3d2d2;
      border-radius: 0;
      box-shadow: none !important;
    }
  }
}

.gift-sending-msg {

  .v-input__slot {
    border: 1px solid #d3d2d2;
    border-radius: 0;
    box-shadow: none !important;
  }
}

.preview-shipping-wrapper {
  width: 565px;
  padding-right: 40px;

  .shipping-input-row {
    clear: both;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    letter-spacing: 0;
  }

  .recipient-name-group {
    display: flex;

    .recipient-name {
      max-width: 184px;
      border: none;
    }

    .company-name,
    .street-address,
    .city-name,
    .region-select-form {
      min-width: 368px;
    }
  }
}

.update_recipient_cancel {
  &::before {
    background: none;
  }
}

.resend-gemail_review-note {
  display: flex;
  height: 36px;
  align-items: center;
}

.order-alert {
  .v-icon {
    color: #219358;
  }
}

.e-gift-order-status {
  background: #fff !important;
  border: none !important;
  padding: 8px 16px;
  opacity: 1 !important;

  &::after {
    background: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.single-link-campaign {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  cursor: pointer;
  font-family: 'Lato-Bold', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #42B77A;
}

.product-info-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;

  &__label {
    color: #000;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
  }

  &__value {
    color: #95979D;
  }
}
</style>
