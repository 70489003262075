<template>
  <div
    class="collection-custom-price-range"
    :class="{
      'collection-custom-price-range--selected': isSelected,
      'collection-custom-price-range--edit': inEditMode,
    }"
    @click="handleClick()"
  >
    <div class="collection-custom-price-range__content">
      <template v-if="inEditMode">
        <div class="collection-custom-price-range__inputs">
          <div class="collection-custom-price-range__input-wrapper">
            <input
              v-model.number="range.from"
              onkeypress="return event.charCode >= 48"
              @keypress.enter="saveChanges()"
              type="number"
              ref="from"
              min="0"
            >
          </div>

          to

          <div class="collection-custom-price-range__input-wrapper">
            <input
              v-model.number="range.to"
              onkeypress="return event.charCode >= 48"
              @keypress.enter="saveChanges()"
              type="number"
              min="0"
            >
          </div>
        </div>

        <div class="collection-custom-price-range__actions">
          <icons-close-circle
            :height="18"
            :width="18"
            color="#95979D"
            @click.native.stop="cancelEditing()"
          />

          <icons-check-circle
            :width="18"
            :height="18"
            outlineColor="#42B77A"
            outlineCheckColor="#42B77A"
            :outlineStrokeWidth="1"
            @click.native.stop="saveChanges()"
          />
        </div>
      </template>

      <template v-else>
        {{ chipText }}

        <icons-edit
          @click.native.stop="inEditMode = true"
          :height="13"
          :width="13"
          :color="isSelected ? '#fff' : '#9B9B9B'"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateCampaignCollectionsCustomPriceRange',
  props: {
    customBudgetOption: {
      type: Object,
      required: false,
      default: () => ({ to: null, from: null }),
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    inEditMode: false,
    range: {
      from: null,
      to: null,
    },
  }),
  computed: {
    isFilled () {
      const { from, to } = this.range;
      return !isNaN(parseInt(from)) && !isNaN(parseInt(to));
    },
    chipText () {
      const { isFilled, range: { from, to } } = this;
      return !isFilled ? 'Custom...' : `$${from} - $${to}`
    },
  },
  watch: {
    inEditMode: function (val) {
      if (val) {
        this.setRangeDefaultData()
        this.focusFromInput()
      }
    }
  },
  mounted () {
    this.setRangeDefaultData()
  },
  methods: {
    setCustomBudgetOptionData (val) {
      this.$emit('update:customBudgetOption', val)
    },
    setRangeDefaultData () {
      const { customBudgetOption } = this

      this.range = {
        from: customBudgetOption.from,
        to: customBudgetOption.to,
      }
    },
    handleClick () {
      const { isFilled, inEditMode } = this

      if (inEditMode) return

      if (!isFilled) {
        this.inEditMode = true
      } else {
        this.emitSelectWithValue()
      }
    },
    focusFromInput () {
      // TODO nextTick
      setTimeout(() => { this.$refs.from?.focus() }, 0)
    },
    cancelEditing () {
      this.setRangeDefaultData()
      this.inEditMode = false
    },
    saveChanges () {
      const { range, isFilled } = this
      // check if from and to is set
      if (!isFilled) return
      // check if from or to is equal or less than zero
      if (range.from <= 0 && range.to <= 0) return
      // to value cannot be less than from
      if (range.to < range.from) return

      this.setCustomBudgetOptionData({ ...range })
      this.emitSelectWithValue()
      this.inEditMode = false
    },
    emitSelectWithValue () {
      const { from, to } = this.range
      const value = `custom-${from}-${to}`

      this.$emit('select', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.collection-custom-price-range {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #D3D2D2;
  background: #fff;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  transition: color ease-in-out 0.2s,
    border-color ease-in-out 0.1s,
    border-width ease-in-out 0.05s;

  font-size: 13px;
  line-height: 15px;
  font-family: 'Lato-Regular', sans-serif;
  color: #62646A;

  &__content {
    font-family: inherit;
    color: inherit;
    line-height: inherit;
    font-size: inherit;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
  }

  &__inputs {
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #95979D;
    gap: 10px;
  }

  &__input-wrapper {
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & > input {
      color: #000;
      font-size: inherit;
      font-family: inherit;
      font-size: inherit;
      border: none;
      border-bottom: 1px solid #D3D2D2;
      display: flex;
      outline: none;
      flex: 0 1 40px;
      width: 40px;
      padding: 0;
      margin: 0;
      padding-left: 4px;
      // hide arrows
      -moz-appearance: textfield !important;
      appearance: textfield !important;
      -webkit-appearance: none;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
    }

    &::before {
      content: '$';
      color: #D3D2D2;
      font-size: inherit;
      font-family: inherit;
      font-size: inherit;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 6px;

    & > svg {
      min-width: 18px;
      cursor: pointer;
    }
  }

  &--selected {
    background: #6E50FF;
    border-color: #6E50FF;
    color: #fff;
  }

  &--edit {
    cursor: default;
    border-width: 2px;
    border-color: #B0A0FF;
    background: #fff;
  }

  &:not(&--selected):not(&--edit):hover {
    background: #F6F5FF;
    border-color: #D3D2D2;
    color: #74767E;
  }
}
</style>
