<template>
  <v-dialog
    v-model="show"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    content-class="elevation-0"
  >
    <div class="create-campaign-add-more-gifts__wrapper">
      <div class="create-campaign-add-more-gifts__content">
        <panel-header :show.sync="show"></panel-header>

        <h1>Add more gifts</h1>

        <span>Mix & match from all three</span>

        <create-campaign-source-list />
      </div>

      <v-icon color="white" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import PanelHeader from '@/components/PanelHeader.vue'
import CreateCampaignSourceList from './CreateCampaignSourceList.vue'

export default {
  name: 'CreateCampaignSourceReviewAddMoreGiftsModal',
  mixins: [panelVModel],
  components: {
    PanelHeader,
    CreateCampaignSourceList,
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-add-more-gifts {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: fit-content;
    margin-inline: auto;

    @media screen and (max-width: 430px) {
      width: 100%;
      height: 100vh;

      & > .v-icon {
        display: none;
      }
    }
  }

  &__content {
    gap: 40px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px 60px;

    & > h1 {
      font-family: 'Lato-Light', sans-serif;
      font-weight: 300;
      font-size: 40px;
      line-height: 65px;
      color: #000000;
    }

    & > span {
      margin-top: -14px;
      color: #62646A;
      font-family: 'Lato', sans-serif;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }

    & > .panel-header {
      width: 100%;
      display: none;
    }

    @media screen and (max-width: 430px) {
      padding: 16px;
      gap: 30px;
      height: 100%;
      width: 100%;
      border-radius: 4px;

      & > h1 {
        font-size: 32px;
        line-height: 38px;
      }

      & > span {
        margin-top: -20px;
        font-size: 14px;
        line-height: 16px;
      }

      & > .panel-header {
        display: flex;
        margin-bottom: -14px;
      }
    }
  }
}
</style>
