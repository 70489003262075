import axios from 'axios'
import Api from '../axios/api'

import copyTextToClipboard from '@/utils/copyTextToClipboard'

export default {
  namespaced: true,

  state: () => ({
    recipients: [],
    search: undefined,
    orderBy: undefined,
    orderDesc: undefined,
    links: {
      first: undefined,
      last: undefined,
      next: undefined,
      prev: undefined
    },
    meta: {
      current_page: undefined,
      from: undefined,
      last_page: undefined,
      path: undefined,
      per_page: undefined,
      to: undefined,
      total: undefined
    },
    page: undefined,
    product: {
      id: -1,
      order_number: '',
      date: '',
      message: '',
      recipient: undefined,
      recipient_count: 0,
      recipient_redeemed_count: 0,
      recipient_not_redeem_count: -1,
      estimated_price: '',
      product_image: null,
      product_name: '',
      product_sku: '',
      actual_cost: -1,
      shipping: -1,
      tax: -1,
      total_actual: -1
    },
    single_link_campaign_link: null,
    isDownloaded: undefined,
    sendLink: undefined,
    resendNotificationStatus: undefined,
    recipientID: undefined,
    giftOptions: undefined,
    selectedGiftID: undefined,
    recipientHash: null,
    targetMail: undefined,
    targetPhoneNumber: undefined,
    resendGiftData: undefined,
    copied: false,
    resendingGiftFlag: undefined,
    dateRangeStart: undefined,
    dateRangeEnd: undefined,
    filteredStatus: undefined,
    giver: undefined,
    backButton: {
      text: 'Back to My E-gift Orders',
      url: '/e-gift-orders'
    },
    ordersSize: undefined,
    sendingNotification: false,
    loadingRecipients: false,
    loadingSummary: false
  }),

  getters: {
    getItems (state) {
      return state.items
    },
    backButton: state => state.backButton,
    getProductState: state => {
      return state.product.state || undefined
    },
    egiftId: state => JSON.parse(localStorage.getItem('egiftId')),
    egiftFilters: state => JSON.parse(localStorage.getItem('egiftFilters')),
    loading: state => state.loadingRecipients || state.loadingSummary,
    orderSingleLinkCampaign: state => state.single_link_campaign_link
  },

  mutations: {
    setId (state, id) {
      state.id = id
      state.search = undefined
      state.page = undefined
    },
    setProduct (state, product) {
      state.product = product
      state.ordersSize = state.product.egift_count
      state.single_link_campaign_link = null
      localStorage.setItem('ordersSize', state.ordersSize)
    },
    setRecipients (state, recipients) {
      state.recipients = recipients
    },
    setLinks (state, links) {
      state.links = links
    },
    setMetaData (state, meta) {
      state.meta = meta
    },
    setSearch (state, search) {
      state.search = search
    },
    setDateRange (state, range) {
      state.dateRange = range
    },
    setSortParam (state, param) {
      state.orderBy = param.column
      state.orderDesc = param.isDesc
    },
    setPage (state, page) {
      state.page = page
    },
    notDownloaded (state) {
      state.isDownloaded = false
    },
    setSendLink (state, sendLink) {
      state.sendLink = sendLink
    },
    copySendLink (state) {
      copyTextToClipboard(state.sendLink)
      state.copied = true
    },
    setNotificationStatus (state, data) {
      state.resendNotificationStatus = data
      state.sendingNotification = false
    },
    setRecipientID (state, id) {
      state.recipientID = id
    },
    setGiftOptions (state, data) {
      state.giftOptions = data
      if (state.giftOptions.length === 1) {
        state.selectedGiftID = state.giftOptions[0].id
      }

      if (state.giftOptions.length > 1) {
        const ids = state.giftOptions.map(item => item.id)
        state.selectedGiftID = ids
      }
    },
    setSelectedGiftID (state, id) {
      state.selectedGiftID = id
    },
    setTargetMail (state, mail) {
      state.targetMail = mail
    },
    setTargetPhoneNumber (state, phone) {
      state.targetPhoneNumber = phone
    },
    resetCopiedStatus (state) {
      state.copied = false
    },
    resetResendingGiftFlag (state) {
      state.resendingGiftFlag = undefined
    },
    setBackButton (state, obj) {
      state.backButton = obj
    },
    setEgiftId (state, egiftId) {
      localStorage.setItem('egiftId', JSON.stringify(egiftId))
    },
    setEgiftFilters (state, egiftFilters) {
      localStorage.setItem('egiftFilters', JSON.stringify(egiftFilters))
    },
    setProductSingleLinkCampaign (state, url) {
      state.single_link_campaign_link = url
    },
    setRedeemGiftHash (state, hash = null) {
      state.recipientHash = hash
    }
  },

  actions: {
    async loadSummary ({ state, commit, dispatch }) {
      commit('setProduct', {})
      state.loadingSummary = true
      const id = JSON.parse(localStorage.getItem('egiftId'))

      await Api.get('/customer/recipients/summary', {
        params: {
          id,
          ...JSON.parse(localStorage.getItem('egiftFilters'))
        }
      })
        .then(({ data }) => {
          commit('setProduct', data)
          if (['single_link_restricted', 'single_link_unrestricted'].includes(data?.link_type)) {
            dispatch('loadSingleLink', { egiftId: id })
          }
          state.loadingSummary = false
        })
        .catch(e => console.error(e))
    },
    async loadRecipients ({ state, commit, dispatch }) {
      commit('setRecipients', [])
      state.loadingRecipients = true
      await Api.get('/customer/recipients', {
        params: {
          id: JSON.parse(localStorage.getItem('egiftId')),
          ...JSON.parse(localStorage.getItem('egiftFilters')),

          recipient_search: state.search,
          order_by: state.orderBy,
          order_desc: state.orderDesc,
          page: state.page
        }
      })
        .then(response => {
          commit('setRecipients', response.data)
          commit('setLinks', response.links)
          commit('setMetaData', response.meta)
          state.loadingRecipients = false

          for (let i = 0; i < state.recipients.length; i++) {
            dispatch('getShippingState', i)
          }
        })
        .catch(e => console.error(e))
    },
    async loadCSV ({ state, commit, getters }) {
      commit('downloadingCSV', true, { root: true })
      await Api.get('/customer/recipients/csv', {
        params: {
          id: JSON.parse(localStorage.getItem('egiftId')),
          ...JSON.parse(localStorage.getItem('egiftFilters')),

          recipient_search: state.search,
          date_from: state.dateRangeStart || undefined,
          date_to: state.dateRangeEnd || undefined,
          status: state.filteredStatus,
          customer_id: state.giver !== undefined ? state.giver.id : undefined
        }
      })
        .then(response => {
          commit('downloadCSV', response, { root: true })
        })
        .catch(e => console.error(e))
    },
    async search ({ commit, dispatch }, search) {
      commit('setSearch', search)
      commit('setPage', 1)
      await dispatch('loadRecipients')
    },
    async sortTable ({ commit, dispatch }, param) {
      commit('setSortParam', param)
      await dispatch('loadRecipients')
    },
    async loadSelectedPage ({ commit, dispatch }, page) {
      commit('setPage', page)
      await dispatch('loadRecipients')
    },
    async updateRecipient ({ state, commit, dispatch }, recipient) {
      await Api.put('/customer/recipients/' + recipient.id, {
        first_name: recipient.first_name,
        last_name: recipient.last_name,
        email_address: recipient.email_address,
        phone_number: recipient.phone_number
      })
        .then(response => {
          const idx = state.recipients.findIndex(item => item.id === recipient.id)
          state.recipients = [...state.recipients.slice(0, idx), response.data, ...state.recipients.slice(idx + 1)]
        })
        .catch(e => console.error(e))
    },
    async getGiftOrderSendLink ({ state, commit }, recipient) {
      state.recipient = recipient
      await Api.get('/customer/recipients/' + recipient.id + '/short-link')
        .then(response => {
          commit('setSendLink', response)
          commit('copySendLink')
        })
        .catch(e => console.error(e))
    },
    async getShippingState ({ state, commit }, idx) {
      if (state.recipients[idx].shipping_status === null) {
        return
      }
      const str = state.recipients[idx].shipping_status.split('/')
      state.recipients[idx].shipping_status = ''
      await Api.get('/customer/recipients/' + str[str.length - 2] + '/shipping-status')
        .then(({ data }) => {
          state.recipients[idx].shipping_status = data.length > 1 ? 'Multiple' : data.length === 1 ? data[0].status : 'N/A'
          state.recipients[idx].shipping_data = data || []
        })
        .catch(e => console.error(e))
    },
    async resendNotification ({ state, commit }, rep) {
      state.sendingNotification = true
      await Api.put(`/customer/recipients/${rep.id}/send-email`, {
        email: rep.email_address,
        message: rep.message,
        subject: rep.subject
      }).then(() => {
        commit('setNotificationStatus', { success: true, message: 'Email send successfully' })
      }).catch(e => console.error(e))
    },
    async getGiftOptions ({ state, commit }, id) {
      return await Api.get('/customer/recipients/' + id + '/options')
        .then(response => {
          commit('setGiftOptions', response.data)
        })
        .catch(e => console.error(e))
    },
    async cancelGift ({ state, dispatch }, id) {
      return await Api.put('/customer/recipients/' + id + '/cancel')
        .then(response => {
          dispatch('loadRecipients')
        })
        .catch(e => console.error(e))
    },
    redeemGift ({ state, dispatch }, payload) {
      state.resendingGiftFlag = true
      const redeemBody = {
        ...payload,
        country: 'US',
        product_id: [state.selectedGiftID]
      }
      const recipientHash = state.recipientHash
      Api.post(`recipient/${recipientHash}/redeem`, redeemBody)
        .then((data) => {
          if (data.success) {
            dispatch('sendThankYouNote', {
              recipientHash, sender: payload.senderName, note: payload.thankyou_message,
            })
            dispatch('loadRecipients')
          } else {
            const errorMessage = data?.message || 'An error occurred, please contact our support'
            this._vm.$cgToast.error(errorMessage)
          }
        })
        .catch(() => {
          this._vm.$cgToast.error('An error occurred, please contact our support')
        })
        .finally(() => (state.resendingGiftFlag = false))
    },
    sendThankYouNote ({ commit }, { recipientHash, sender, note }) {
      Api.post(`recipient/${recipientHash}/thankyounotes`, {
        note, sender, color: '#D3C5D7',
      })
    },
    async loadSingleLink ({ commit }, { egiftId }) {
      Api.get(`customer/egifts/${egiftId}/single-link`)
        .then(({ data }) => {
          commit('setProductSingleLinkCampaign', data?.url)
        })
    }
  }
}
