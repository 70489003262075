import Vue from 'vue'
import CgHeader from 'cg-header/dist/cg-header.common'

import App from './App.vue'
import router from './router'
import store from './store'
import VTooltip from 'v-tooltip';
import CgToast from '@corporategift/design-system/plugins/CgToast';
import vuetify from './plugins/vuetify'
import VLockScroll from './plugins/vLockScroll'
import VScrollLock from 'v-scroll-lock'
import FormsComparator from './plugins/FormsComparator'

import './components'

import { priceFilter, numberWithSeparator, convertRegionToAbbr, date, dateFilter } from '@/filters'

Vue.filter('priceFilter', priceFilter)
Vue.filter('numberWithSeparator', numberWithSeparator)
Vue.filter('convertRegionToAbbr', convertRegionToAbbr)
Vue.filter('date', date)
Vue.filter('dateFilter', dateFilter)

Vue.use(VScrollLock)
Vue.use(VLockScroll)
Vue.use(VTooltip, { disposeTimeout: 500 })
Vue.use(CgToast, { left: 80, bottom: 16 })
Vue.use(CgHeader, {
  store,
  magentoUrl: process.env.VUE_APP_MAGENTO_URL,
  accountUrl: window.location.origin,
  apiUrl: process.env.VUE_APP_API_URL,
  cmsUrl: process.env.VUE_APP_CMS_URL || 'https://cms.corporategift.com',
  storeUrl: process.env.VUE_APP_MAGENTO_URL, // used by algolia for products
  algoliaAppId: process.env.VUE_APP_ALGOLIA_APP_ID,
  algoliaAppKey: process.env.VUE_APP_ALGOLIA_APP_KEY,
  algoliaIndexName: 'stage_products',
  testMode: process.env.VUE_APP_TEST_MODE, // only for tests
  testEmail: process.env.VUE_APP_TEST_EMAIL, // used when test mode is set to true
  testPassword: process.env.VUE_APP_TEST_PASSWORD // used when test mode is set to true
})

window.formsComparator = new FormsComparator();

window.routerLength = 0

Vue.config.productionTip = false

Vue.prototype.$header = Vue.observable({ height: 160, isVisible: true })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export const eventBus = new Vue()
