import { render, staticRenderFns } from "./CreateCampaignCollectionsCustomPriceRange.vue?vue&type=template&id=1040b3e7&scoped=true"
import script from "./CreateCampaignCollectionsCustomPriceRange.vue?vue&type=script&lang=js"
export * from "./CreateCampaignCollectionsCustomPriceRange.vue?vue&type=script&lang=js"
import style0 from "./CreateCampaignCollectionsCustomPriceRange.vue?vue&type=style&index=0&id=1040b3e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1040b3e7",
  null
  
)

export default component.exports