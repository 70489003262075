<template>
  <div>
    <v-row v-if="panelType === 'link'" class="pb-8">
      <v-col cols="12" class="recipients-area__title">
        Send campaign with gift-link
      </v-col>

      <v-col v-if="isCampaignUSOnly" cols="12">
        <cg-alert type="information" background-color="#FCEBA4">
          This campaign can be shipped to {{ shippingType === 'us_only' ? 'US' : 'US & Canada' }} recipients only!
        </cg-alert>
      </v-col>

      <v-col cols="12" class="d-flex">
        <cg-select
          v-model="orderLinkType"
          :items="linkTypeItems"
          placeholder="Select"
          style="min-width: 300px; flex-grow: 0; gap: 16px;"
        >
          <template #label>Select gift-link type</template>
        </cg-select>

        <link-type-more-info :link-type="orderLinkType" />
      </v-col>
    </v-row>

    <div
      v-if="panelType === 'link' && orderLinkType !== 'single_link_unrestricted'"
      class="link-type-panel-title"
    >
      <div>
        <b>Add recipients manually</b>
      </div>
      <div>
        Or <span @click="$emit('showBulkUpload')">bulk upload</span>
      </div>
    </div>

    <v-row v-if="showSingleLinkUnrestricted">
      <v-col cols="12">
        <the-unrestricted-gift-link
          v-bind="_props"
          v-on="$listeners"
        />
      </v-col>
    </v-row>

    <v-form
      v-if="showNormalFlow"
      ref="recipientsForm"
      @submit.prevent="goToPreviewEGift()"
    >
      <v-row>
        <v-col v-if="panelType !== 'link'" cols="12" class="recipients-area__title">
          Enter recipients manually
        </v-col>

        <v-col v-if="panelType !== 'link' && isCampaignUSOnly" cols="12">
          <cg-alert type="information" background-color="#FCEBA4">
            This campaign can be shipped to {{ shippingType === 'us_only' ? 'US' : 'US & Canada' }} recipients only!
          </cg-alert>
        </v-col>

        <v-col cols="12">
          <template v-for="(recipient, index) in manualRecipients">
            <v-row :key="index">
              <v-col :cols="panelType === 'link' ? 4 : 3">
                <label :for="`recipient-first-name-${index}`">
                  First name
                </label>
                <common-input
                  v-model="recipient.first_name"
                  :rules="[v => !!v || '']"
                  :id="`recipient-first-name-${index}`"
                  placeholder="First name"
                  height="36"
                />
              </v-col>
              <v-col :cols="panelType === 'link' ? 4 : 3">
                <label :for="`recipient-last-name-${index}`">
                  Last name (optional)
                </label>
                <common-input
                  v-model="recipient.last_name"
                  :id="`recipient-last-name-${index}`"
                  placeholder="Last name"
                  height="36"
                />
              </v-col>
              <v-col :cols="panelType === 'link' ? 4 : 3">
                <label :for="`recipient-email-${index}`">
                  Email {{ emailIsOptional ? '(optional)' : null }}
                </label>

                <div class="d-flex align-center justify-space-between">
                  <common-input
                    v-model="recipient.email"
                    :rules="emailIsOptional ? [] : [v => !!v || '', v => /.+@.+\..+/.test(v)]"
                    :error="isEmailDuplicated(recipient.email)"
                    :id="`recipient-email-${index}`"
                    placeholder="Email"
                    height="36"
                  />

                  <div
                    class="d-flex pointer ml-4"
                    @click="deleteRecipient(index)"
                    v-if="manualRecipients.length > 1 && panelType === 'link'"
                  >
                    <icons-delete :width="14" :height="17" />
                  </div>
                </div>
              </v-col>
              <v-col cols="3" v-if="panelType !== 'link'">
                <label :for="`recipient-send-date-${index}`">
                  Send date
                </label>
                <div class="d-flex align-center justify-space-between">
                  <single-date-range-picker
                    v-model="recipient.send_date"
                    :id="`recipient-send-date-${index}`"
                    @change="(val) => handleSendDateChange(val)"
                    picker-style="--min-width: 160px;--picker-margin-top: -1px;"
                    showCheckbox
                    :checkboxValue.sync="setSendDateForAllRecipients"
                  />

                  <div
                    class="d-flex pointer"
                    @click="deleteRecipient(index)"
                    v-if="manualRecipients.length > 1"
                  >
                    <icons-delete :width="14" :height="17" />
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col cols="12">
          <green-text-with-plus @onClick="addNewRecipient()">
            Add another recipient
          </green-text-with-plus>
        </v-col>

        <v-col cols="12">
          <v-divider class="my-4" />
        </v-col>

        <v-col cols="12">
          <common-button
            height="45"
            style="width: 280px"
            @click="goToPreviewEGift()"
          >
            Continue to review eGift
          </common-button>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import GreenTextWithPlus from '@/components/myCampaign/common/GreenTextWithPlus.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'
import TheUnrestrictedGiftLink from './TheRecipientsAreaUnrestrictedGiftLink.vue'
import LinkTypeMoreInfo from '../LinkTypeMoreInfo.vue'
import { CgSelect, CgAlert } from '@corporategift/design-system'

import panelType from '@/components/myCampaign/panels/mixins/panelType'
import marketplaceUser from '@/components/myCampaign/panels/mixins/marketplaceUser'
import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'

export default {
  name: 'TheRecipientsAreaSelectManually',
  components: {
    CgAlert,
    CgSelect,
    LinkTypeMoreInfo,
    GreenTextWithPlus,
    SingleDateRangePicker,
    TheUnrestrictedGiftLink,
  },
  mixins: [
    panelType,
    marketplaceUser,
    takeCurrentDate,
  ],
  props: {
    linkType: {
      type: String,
      required: false,
      default: null
    },
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    },
    shippingType: {
      type: String,
      required: false,
      default: null
    },
  },
  data: () => ({
    setSendDateForAllRecipients: false,
    sendDateForNewRecipient: null,
    manualRecipients: [],
    linkTypeItems: [
      {
        text: 'Create a personal link for each recipient',
        value: 'standard_link',
      },
      {
        text: 'Create a single link with verified email',
        value: 'single_link_restricted',
      },
      {
        text: 'Create a generic link (anyone can redeem)',
        value: 'single_link_unrestricted',
      },
    ],
  }),
  computed: {
    orderLinkType: {
      get () { return this.linkType },
      set (val) { this.$emit('update:linkType', val) },
    },
    isCampaignUSOnly () {
      return this.shippingType !== 'international' || false
    },
    isEveryEmailUnique () {
      const { manualRecipients } = this
      const emails = manualRecipients?.map((recipient) => recipient.email).filter((email) => email) ?? []

      return new Set(emails).size === emails.length
    },
    showSingleLinkUnrestricted () {
      const { panelType, linkTypeItems, orderLinkType } = this
      if (panelType !== 'link') { return false }
      return orderLinkType === linkTypeItems[2].value
    },
    showNormalFlow () {
      const { panelType, linkTypeItems, orderLinkType } = this
      if (panelType !== 'link') { return true }
      if (!orderLinkType) { return false }
      return orderLinkType !== linkTypeItems[2].value
    },
    emailIsOptional () {
      const { panelType, orderLinkType } = this

      if (panelType === 'egift') { return false }
      if (orderLinkType === 'single_link_restricted') { return false }

      return true
    },
  },
  watch: {
    manualRecipients: {
      deep: true,
      handler (recipients) {
        const userHasChanges = recipients.map((recipient) => {
          if (recipient.email || recipient.first_name || recipient.last_name) return true
          return false
        })
        this.$emit('setUnsavedChanges', userHasChanges.includes(true))
      }
    },
    setSendDateForAllRecipients: function (val) {
      if (!val) this.sendDateForNewRecipient = null
    }
  },
  created () {
    this.addNewRecipient()
  },
  methods: {
    addNewRecipient () {
      this.manualRecipients.push({
        first_name: '',
        last_name: '',
        email: '',
        send_date: this.sendDateForNewRecipient || this.takeCurrentDate()
      })
    },
    deleteRecipient (indexToRemove) {
      this.manualRecipients = this.manualRecipients?.filter((item, index) => index !== indexToRemove) || []
    },
    handleSendDateChange (sendDate) {
      const { setSendDateForAllRecipients, manualRecipients } = this
      if (setSendDateForAllRecipients) {
        this.sendDateForNewRecipient = sendDate
        manualRecipients.forEach((recipient) => {
          recipient.send_date = sendDate
        })
      }
    },
    goToPreviewEGift () {
      if (this.$refs.recipientsForm.validate() && this.isEveryEmailUnique) {
        this.$emit('showPreview', this.manualRecipients)
      }
    },
    isEmailDuplicated (emailToCheck) {
      const { manualRecipients } = this
      if (!emailToCheck) return false

      const emails = manualRecipients?.map((recipient) => recipient.email) ?? []
      return emails.filter((email) => email === emailToCheck).length !== 1
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-family: 'Lato-Bold', sans-serif !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}

.link-type-panel-title {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 0 30px;

  & > div {
    font-family: 'Lato', sans-serif;
    line-height: normal;
    font-weight: 400;
    color: #222325;

    & > b {
      font-weight: 700;
    }

    & > span {
      cursor: pointer;
      color: #219358;
    }
  }
}
</style>
